import { gql } from '@apollo/client';

// TODO: remove listNames from here as well
// we shouldn't need to go back and forth with these
// generic lists from client to server and vice-versa
export const AdminUserQuery = gql`
  query getUserQuery {
    adminUserQuery {
      _id
      email
      firstName
      lastName
      photo
    }
  }
`;

export const UserQuery = gql`
  query getUserQuery($listNames: [String]!) {
    currentUserQuery {
      _id
      authToken
      cellNumber
      companyName
      companyUrl
      companyZip
      email
      firstName
      lastName
      photo
      isImpersonating
      stripeToken
      stripePaymentSources {
        name
        token
        institution
        lastFour
        isCC
      }
      privileges {
        canUserManageMultipleSites
        canUserSiteUseItems
        canUserSiteUseTax
        canUserAddListings
        canUserAddTransactions
        canUserEditMarketplacePosts
        canUserEditSelfReportedDonations
        canUserSiteEditWasteRecords
        canUserAddBulkImport
        canViewTaxDashboard
        canViewItems
        canUserEditItems
        canUserViewMarketplace
        canViewClaims
        canViewPosts
        canViewActivity
        canViewEnterpriseDashboard
        hideActivitySubMenu
        canViewMailbox
        isSystemAdmin
        isSiteAdmin
        canViewDropdown
        canViewUserManagement
        canViewInventory
        canMakeOffers
        canReceiveOffers
      }
      postFilterParameters {
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categoriesForVerifications {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        qualities {
          id
          name
        }
      }
      site {
        _id
        siteName
        businessWebsite
        isActive
        fiscalYearStart
        allTimeStart
        isNonProfit
        itemImportFileUrl
        transactionImportFileUrl
        transactionCycleId
        privClasses {
          name
          displayName
        }
        destinationColorMapping {
          destination
          color
        }
        charities {
          siteId
          siteName
          userId
        }
        destinations {
          name
          value
          enumValue
          outlets {
            siteId
            siteName
            userId
          }
        }
        physicalAddress {
          streetAddress
          city
          stateCode
          zipCode
          content
          loc {
            type
            coordinates
          }
        }
        lists(listNames: $listNames) {
          _id
          listName
          locale
          listElements {
            item
            mapsTo
            rank
            isDefault
          }
        }
        children {
          _id
          siteName
          businessWebsite
          isActive
          fiscalYearStart
          isNonProfit
          physicalAddress {
            content
            loc {
              type
              coordinates
            }
          }
        }
        responsibleParty {
          name
          signatureImage
          title
        }
        dataTableProfiles {
          dataTableName
          siteName
          siteId
          columns {
            field
            displayName
            visible
            sortable
            defaultSort
            defaultSortDirection
            formatter
            editFormatter
          }
        }
      }
      siteHierarchy {
        _id
        siteName
        itemImportFileUrl
      }
      dashboard {
        layout {
          numCols
          rows {
            widgetId
            widgetName
            offset {
              xs
              sm
              md
              lg
              xl
            }
            width {
              xs
              sm
              md
              lg
              xl
            }
            componentType
            title
            filters {
              query
              id
              numberClass
              text
              value
              mask
              aggregationType
              gql
            }
          }
        }
      }
    }
  }
`;

export const ActiveAdminUsersQuery = gql`
  query ActiveAdminUsersQuery($paginate: PaginatedAdminUsersInput) {
    adminUserQuery {
      _id
      users(paginate: $paginate) {
        total
        pageContents {
          _id
          firstName
          lastName
          photo
          email
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const GetActiveAdminUserQuery = gql`
  query GetActiveAdminUserQuery($id: String!) {
    adminUserByIdQuery(id: $id) {
      _id
      firstName
      lastName
      email
      invitedBy
      createdAt
      lastLoginDate
    }
  }
`;

export const PendingAdminUsersQuery = gql`
  query PendingAdminUsersQuery($paginate: PaginatedAdminInvitesInput) {
    adminUserQuery {
      _id
      invites(paginate: $paginate) {
        total
        pageContents {
          _id
          firstName
          lastName
          email
          expired
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const GetPendingAdminUserQuery = gql`
  query GetPendingAdminUserQuery($id: String!) {
    adminInviteByIdQuery(id: $id) {
      _id
      firstName
      lastName
      email
      expired
      createdByName
      createdAt
      expiresAt
    }
  }
`;

export const SiteNameTypeAheadQuery = gql`
  query SiteNameTypeAheadQuery($typeAhead: TypeAhead) {
    siteNameTypeAheadQuery(typeAhead: $typeAhead) {
      displayText
      displaySubText
      result {
        siteName
        _id
        fiscalYearStart
        defaultPricingStrategy
        defaultPricingPercentage
        tags
        itemImportEmailRecipients
        inventoryImportEmailRecipients
        siteRole
        physicalAddress {
          streetAddress
          zipCode
          city
          stateCode
          countryCode
        }
        charities {
          siteId
          siteName
        }
        awardSheetEmailTemplate
        itemImportSite
        archived
        archivedReason
      }
    }
  }
`;

export const SiteRoleTypeAheadQuery = gql`
  query SiteRoleTypeAheadQuery($typeAhead: TypeAhead) {
    siteRoleTypeAheadQuery(typeAhead: $typeAhead) {
      displayText
      result {
        siteName
        _id
        fiscalYearStart
        taxId
        defaultPricingStrategy
        defaultPricingPercentage
        itemImportEmailRecipients
        inventoryImportEmailRecipients
        siteRole
        physicalAddress {
          streetAddress
          zipCode
          city
          stateCode
          countryCode
        }
        charities {
          siteId
          siteName
        }
        awardSheetEmailTemplate
        distributionChannels {
          _id
          name
        }
      }
    }
  }
`;

export const BsrNameTypeAheadQuery = gql`
  query BsrNameTypeAheadQuery($typeAhead: TypeAhead) {
    bsrNameTypeAheadQuery(typeAhead: $typeAhead) {
      displayText
      result {
        name
        type
        sellerSiteId
        id
        buyerSiteId
        sellerUserEmails
        buyerUserEmails
        buyerSiteName
        archived
      }
    }
  }
`;

export const ImportProfileQuery = gql`
  query ImportProfileQuery($siteId: String!) {
    importProfileQuery(siteId: $siteId) {
      _id
      importType
      siteId
      importName
    }
  }
`;

export const AdminSitesQuery = gql`
  query adminSitesQuery($paginate: PaginatedAdminSitesFilterInput) {
    adminUserQuery {
      _id
      customerSites(paginate: $paginate) {
        total
        pageContents {
          _id
          siteName
          rootSite
          siteRole
          siteType
          transactionCycle {
            _id
            startedAt
            endedAt
            status
          }
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const DistributionListsBySiteQuery = gql`
  query distributionListsBySiteQuery($siteId: String!) {
    distributionListsBySiteQuery(siteId: $siteId) {
      name
      destinationType
      offerEmailTemplate
      archived
      offerEmailCustomContent
      sites {
        siteName
      }
      buyerSellerRelationships {
        id
        name
      }
      _id
    }
  }
`;

export const CustomFiltersQuery = gql`
  query customFiltersQuery {
    adminUserQuery {
      customFilters {
        _id
        siteName
        name
        query
        siteId
        importProfileId
      }
      _id
    }
  }
`;

export const BuyerSellerRelationshipQuery = gql`
  query BuyerSellerRelationshipQuery($buyerSiteName: String!, $sellerSiteName: String!) {
    buyerSellerRelationshipQuery(buyerSiteName: $buyerSiteName, sellerSiteName: $sellerSiteName) {
      _id
      sellerSiteId
      buyerSiteId
      sellerUserEmails
      buyerUserEmails
    }
  }
`;

export const InventoryQuery = gql`
  query InventoryQuery($id: String!) {
    inventoryByIdQuery(_id: $id) {
      _id
      description
      bestByDate
      distributionLists {
        listedAt
        published
        listId
      }
    }
  }
`;

export const GetDataExportQuery = gql`
  query GetDataExportQuery($exportRunId: String!) {
    getDataExportsQuery(exportRunId: $exportRunId) {
      _id
      exporterSite
      exportRunId
      distributionChannelId
      buyerSiteId
      exportStatus
      exportType
      buyerSiteName
      sellerSiteName
      exportedAt
      hasSnapshot
      bsrName
      distributionChannelName
      updatedAt
    }
  }
`;

export const TimebombsQuery = gql`
  query TimebombsQuery {
    getTimebombsQuery {
      _id
      event
      firingID
      expires
    }
    getRulesQuery {
      _id
      eventName
      eventId
      enabled
    }
  }
`;

export const RemindersQuery = gql`
  query getRemindersQuery {
    getRemindersQuery {
      list {
        _id
        sellerSiteId
        sellerSiteName
        distributionListId
        distributionListName
        offsetValue
        offsetUnit
        enabled
        reminderType
        createdAt
      }
      reminderTypes
    }
  }
`;

export const FeatureFlagsQuery = gql`
  query getFeatureFlagsQuery {
    getFeatureFlagsQuery {
      flags {
        _id
        featureName
        siteIds
        enabled
      }
      featureNames
      sellerSites {
        id
        name
      }
    }
  }
`;

export const AdminDashboardListQuery = gql`
  query adminDashboardListQuery {
    adminDashboardListQuery {
      dashboards {
        _id
        name
        reportId
        uname
        type
        defaultForLocation
        sellerSiteIds
        orgFilterPaths
        drillthroughs
        createdAt
        updatedAt
      }
      sellerOrgs {
        _id
        siteName
      }
    }
  }
`;

export const AdminDashboardRenderQuery = gql`
  query adminDashboardRenderQuery($dashboardIds: [String]!, $sellerSiteId: String!) {
    adminDashboardRenderQuery(dashboardIds: $dashboardIds, sellerSiteId: $sellerSiteId) {
      _id
      url
    }
  }
`;

export const AdminDashboardDrillthroughsQuery = gql`
  query adminDashboardDrillthroughsQuery($dashboardId: Float!) {
    adminDashboardDrillthroughsQuery(dashboardId: $dashboardId) {
      name
      dashboardId
    }
  }
`;

export const BuyerManagerQuery = gql`
  query buyerManagerQuery {
    buyerManagerQuery {
      sites {
        _id
        siteName
        companyId
        creditLimit
        creditRating
        creditVerificationStatus
      }
    }
  }
`;

export const BuyerManagerDetailQuery = gql`
  query buyerManagerDetailQuery($buyerSiteId: String) {
    buyerManagerDetailQuery(buyerSiteId: $buyerSiteId) {
      _id
      name
      type
      status
      kybStatus
      creditLimit
      creditRating
      creditVerificationStatus
      onboardingURL
      invoices {
        _id
        invoiceNumber
        denomination
        totalAmount
        items {
          _id
          description
          quantity
          unitCost
          lineTotal
        }
        note
        status
        dueDate
      }
    }
  }
`;
